import * as React from 'react';
import { SignalLoading } from '@sharefiledev/icons';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Layout } from 'antd';
import { Redirect } from 'react-router-dom';
import { AsyncBlockDevelopment } from './AsyncBlockDevelopment';
import { AsyncRIDRoutingComponent } from './AsyncRIDRoutingComponent';
import { registerBlocks } from './Blocks';
import {
	LazyDevelopmentPage,
	LazyRIDPage,
	LazyViewBuilder,
	LazyViewEngineDevelopmentPage,
	LazyViewRenderer,
} from './Pages';
import {
	ViewEngineBlockDevelopmentExtensionParams,
	viewEngineExtensionSlots,
} from './sdk';

export function setup(piletApi: PiletApi) {
	piletApi.registerExtension<ViewEngineBlockDevelopmentExtensionParams>(
		viewEngineExtensionSlots.blockDevelopment,
		({ params }) => <AsyncBlockDevelopment {...params} piletApi={piletApi} />
	);
	piletApi.registerExtension<ViewEngineBlockDevelopmentExtensionParams>(
		viewEngineExtensionSlots.blockDevelopmentLatest,
		({ params }) => <AsyncBlockDevelopment {...params} piletApi={piletApi} />
	);
	piletApi.registerExtension(viewEngineExtensionSlots.ridLandingPage, () => (
		<AsyncRIDRoutingComponent piletApi={piletApi} />
	));

	registerBlocks(piletApi);

	// Shouldn't this be in the manifest?
	piletApi.registerExtension('urn:block:view-engine:redirect-to-root', () => (
		<Redirect to="/" />
	));

	// As of react-router <=5, we must list the deeper nested path
	// first. Ordering of registrations matter.
	piletApi.registerPage('/view/:slug+', () => <LazyViewRenderer piletApi={piletApi} />);
	piletApi.registerPage('/view', () => <LazyViewRenderer piletApi={piletApi} />);

	piletApi.registerPage('/v/resource', () => <LazyRIDPage piletApi={piletApi} />, {
		pageType: 'redirect',
	});

	const knownSmokeTestCustomers = [
		'pilet-tests.sharefiletest.com',
		'pilet-tests.sharefilestaging.com',
		'pilet-tests.sharefile.com',
	];
	if (
		process.env.NODE_ENV === 'development' ||
		location.hostname.endsWith('sharefiletest.com') ||
		knownSmokeTestCustomers.includes(location.hostname)
	) {
		piletApi.registerPage(
			'/block-builder-ui',
			() => <LazyDevelopmentPage piletApi={piletApi} />,
			{ pageType: 'fullPage' }
		);
	}
	registerDevelopmentTools(piletApi);
}

function registerDevelopmentTools(piletApi: PiletApi) {
	if (process.env.NODE_ENV === 'development') {
		piletApi.sf.registerLeftNavComponent({
			href: '/view-engine-dev',
			title: () => 'UI Block Dev',
			icon: () => <SignalLoading style={{ fontSize: '24px' }} />,
			weight: 50,
		});

		piletApi.registerPage('/view-engine-dev', () => (
			<LazyViewEngineDevelopmentPage piletApi={piletApi} />
		));

		piletApi.sf.registerLeftNavComponent({
			href: '/view-builder',
			title: () => 'View Builder',
			icon: () => <SignalLoading style={{ fontSize: '24px' }} />,
			weight: 50,
		});

		piletApi.registerPage('/view-builder', () => (
			<LazyViewBuilder piral={piletApi} params={{}} />
		));

		piletApi.registerDrawer(
			'urn:drawer:view-engine-dev',
			() => <Layout.Content style={{ padding: '24px' }}>Drawer Content</Layout.Content>,
			{ drawerProps: {} }
		);

		piletApi.registerModal(
			'urn:modal:view-engine-dev',
			() => <Layout.Content style={{ padding: '24px' }}>Modal Content</Layout.Content>,
			{ modalProps: {} }
		);
	}
}
