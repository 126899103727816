/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */

import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package
import type { UiManifestV1 } from '@sharefiledev/solution-view-engine';

const prefix = 'urn';
const namespace = 'sfblock';
const owner = 'view-engine';

export const viewEngineExtensionSlots = {
	blockDevelopment: `${prefix}:${namespace}:${owner}:blockdevelopment` as const,
	blockDevelopmentLatest: 'urn:block:view-engine:blockdevelopment' as const,
	ridLandingPage: `${prefix}:${namespace}:${owner}:ridlandingpage` as const,
};

export interface ViewEngineBlockDevelopmentExtensionParams {
	initialManifest?: UiManifestV1;
	pluginKey: string;
}

export type DropdownButtonOverlayAdditionalParams<TParams> = {
	params: TParams;
};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		[viewEngineExtensionSlots.blockDevelopment]: ViewEngineBlockDevelopmentExtensionParams;
		[viewEngineExtensionSlots.blockDevelopmentLatest]: ViewEngineBlockDevelopmentExtensionParams;
	}
}
