import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Skeleton, Spin } from 'antd';
import { BlockNames } from '../block.types';
import { LocalizationLoader } from '../translate';

export function registerBlocks(piletApi: PiletApi) {
	const LazyEventButton = React.lazy(() => import('./EventButton'));
	piletApi.registerExtension(BlockNames.EventButton, props => (
		<React.Suspense fallback={<Skeleton.Button shape="round" />}>
			<LazyEventButton {...props} />
		</React.Suspense>
	));

	const LazyCard = React.lazy(() => import('./Card'));
	piletApi.registerExtension(BlockNames.Card, props => (
		<React.Suspense fallback={<Skeleton />}>
			<LazyCard {...props} />
		</React.Suspense>
	));

	const LazyText = React.lazy(() => import('./Text'));
	piletApi.registerExtension(BlockNames.Text, props => (
		<React.Suspense fallback={<Skeleton title={false} />}>
			<LazyText {...props} />
		</React.Suspense>
	));

	const LazyTag = React.lazy(() => import('./Tag'));
	piletApi.registerExtension(BlockNames.Tag, props => (
		<React.Suspense fallback={<Skeleton.Button />}>
			<LazyTag {...props} />
		</React.Suspense>
	));

	const LazyTitle = React.lazy(() => import('./Title'));
	piletApi.registerExtension(BlockNames.Title, props => (
		<React.Suspense fallback={<Skeleton title paragraph={false} />}>
			<LazyTitle {...props} />
		</React.Suspense>
	));

	const LazyContentPageWithTabs = React.lazy(() => import('./ContentPageWithTabs'));
	piletApi.registerExtension(BlockNames.Contentpagewithtabs, props => (
		<React.Suspense fallback={<Skeleton title paragraph={false} />}>
			<LazyContentPageWithTabs {...props} />
		</React.Suspense>
	));

	const AsyncSpace = React.lazy(() => import('./Space'));
	piletApi.registerExtension(BlockNames.Space, props => <AsyncSpace {...props} />);

	const AsyncRow = React.lazy(() => import('./Row'));
	piletApi.registerExtension(BlockNames.Row, props => <AsyncRow {...props} />);

	const LazyContentPage = React.lazy(() => import('./ContentPage'));
	piletApi.registerExtension(BlockNames.Contentpage, props => (
		<React.Suspense fallback={<Skeleton title paragraph={false} />}>
			<LocalizationLoader loadingElement={<Skeleton title paragraph={false} />}>
				{() => <LazyContentPage {...props} />}
			</LocalizationLoader>
		</React.Suspense>
	));

	const LazyDropdownButton = React.lazy(() => import('./DropdownButton'));
	piletApi.registerExtension(BlockNames.DropdownButton, props => (
		<React.Suspense fallback={<Skeleton.Button shape="round" />}>
			<LazyDropdownButton {...props} />
		</React.Suspense>
	));

	const LazyContextMenu = React.lazy(() => import('./ContextMenu'));
	piletApi.registerExtension(BlockNames.ContextMenu, props => (
		<React.Suspense fallback={<Skeleton.Button shape="round" />}>
			<LazyContextMenu {...props} />
		</React.Suspense>
	));

	const LazyTabs = React.lazy(() => import('./Tabs'));
	piletApi.registerExtension(BlockNames.Tabs, props => (
		<React.Suspense fallback={<Skeleton title={false} />}>
			<LocalizationLoader loadingElement={<Skeleton title={false} />}>
				{() => <LazyTabs {...props} />}
			</LocalizationLoader>
		</React.Suspense>
	));

	const LazyShareFileCube = React.lazy(() => import('./ShareFileCube'));
	piletApi.registerExtension(BlockNames.SharefileCube, props => (
		<React.Suspense fallback={<Skeleton title={false} />}>
			<LazyShareFileCube {...props} />
		</React.Suspense>
	));

	const LazyViewLoaderBlock = React.lazy(() => import('./ViewLoaderBlock'));
	piletApi.registerExtension(BlockNames.ViewLoader, props => (
		<React.Suspense fallback={null}>
			<LazyViewLoaderBlock {...props} />
		</React.Suspense>
	));

	const LazyDashboardLayout = React.lazy(() => import('./DashboardLayout'));
	piletApi.registerExtension(BlockNames.DashboardLayout, props => (
		<React.Suspense fallback={<Spin />}>
			<LazyDashboardLayout {...props} />
		</React.Suspense>
	));

	const LazyHomeTiles = React.lazy(() => import('./Tiles'));
	piletApi.registerExtension(BlockNames.ViewDashboard, props => (
		<React.Suspense fallback={<Skeleton title={false} />}>
			<LazyHomeTiles {...props} />
		</React.Suspense>
	));
}
