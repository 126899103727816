/* tslint:disable */
/**
 * This file is autogenerated using @sharefiledev/solution-view-engine-tools.
 *
 * Run 'sve typegen <relative path to manifest file>'
 *
 * Types are generated according to specification in a file that conforms to the UI Manifest schema:
 * https://us-solutionviewengine.sharefiletest.io/ui-manifest-v2.json
 */
import type {
	PiletApi,
	PiralCustomExtensionSlotMap,
} from '@sharefiledev/sharefile-appshell';
import type { BlockParams } from '@sharefiledev/solution-view-engine';

export interface ShellProps {
	piletApi: PiletApi;
}
/** Block parameters for urn:block:view-engine:event-button */

export interface EventButtonBlockParams {
	event?: string;
	/**
	 * Localized String Values
	 */
	buttonText?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	eventData?: {};
	size?: 'large' | 'middle' | 'small';
	shape?: 'default' | 'circle' | 'round';
	disabled?: boolean;
	type?: 'primary' | 'dashed' | 'link' | 'text' | 'default';
	href?: string;
	target?: string;
	htmlType?: 'button' | 'submit' | 'reset';
}
/** Block parameters for urn:block:view-engine:card */

export interface CardBlockParams {
	/**
	 * Localized String Values
	 */
	title?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	bordered?: boolean;
	size?: 'default' | 'small';
}
/** Zone parameters for urn:zone:view-engine:card:tab */

export interface CardTabZoneParams {
	/**
	 * Localized String Values
	 */
	label?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
}
/** Zone parameters for urn:zone:view-engine:card:content */

export interface CardContentZoneParams {}
/** Block parameters for urn:block:view-engine:text */

export interface TextBlockParams {
	strong?: boolean;
	italic?: boolean;
	underline?: boolean;
	type?: 'secondary' | 'success' | 'warning' | 'danger';
	/**
	 * Localized String Values
	 */
	text?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	ellipsis?: boolean;
}
/** Block parameters for urn:block:view-engine:tag */

export interface TagBlockParams {
	/**
	 * Localized String Values
	 */
	text?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	color?: string;
	bordered?: boolean;
	fontSize?: string;
	/**
	 * Localized String Values
	 */
	tooltipTitle?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	tooltipColor?: string;
}
/** Block parameters for urn:block:view-engine:title */

export interface TitleBlockParams {
	level?: 1 | 2 | 3 | 4 | 5;
	italic?: boolean;
	underline?: boolean;
	type?: 'secondary' | 'success' | 'warning' | 'danger';
	/**
	 * Localized String Values
	 */
	text?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	ellipsis?: boolean;
	/**
	 * Override the default font size for a font level. This can be used to style elements while maintaining accessibility.
	 */
	sizeOverride?: number;
}
/** Block parameters for urn:block:view-engine:space */

export interface SpaceBlockParams {
	direction?: 'vertical' | 'horizontal';
	size?: 'large' | 'middle' | 'small';
}
/** Zone parameters for urn:zone:view-engine:space:content */

export interface SpaceContentZoneParams {}
/** Block parameters for urn:block:view-engine:row */

export type HorizontalGutter = number;

export interface RowBlockParams {
	align?: {
		xs?: 'top' | 'middle' | 'bottom' | 'stretch';
		sm?: 'top' | 'middle' | 'bottom' | 'stretch';
		md?: 'top' | 'middle' | 'bottom' | 'stretch';
		lg?: 'top' | 'middle' | 'bottom' | 'stretch';
		xl?: 'top' | 'middle' | 'bottom' | 'stretch';
		xxl?: 'top' | 'middle' | 'bottom' | 'stretch';
	};
	gutter?: BreakpointGutter | HorizontalGutter;
	justify?: {
		xs?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
		sm?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
		md?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
		lg?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
		xl?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
		xxl?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
	};
	wrap?: boolean;
}
export interface BreakpointGutter {
	/**
	 * vertical and horizontal gutter values in pixels
	 */
	xs?: number;
	/**
	 * vertical and horizontal gutter values in pixels
	 */
	sm?: number;
	/**
	 * vertical and horizontal gutter values in pixels
	 */
	md?: number;
	/**
	 * vertical and horizontal gutter values in pixels
	 */
	lg?: number;
	/**
	 * vertical and horizontal gutter values in pixels
	 */
	xl?: number;
	/**
	 * vertical and horizontal gutter values in pixels
	 */
	xxl?: number;
}
/** Zone parameters for urn:zone:view-engine:row:col */

export interface RowColZoneParams {
	xs?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	sm?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	md?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	lg?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	xl?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
	xxl?: {
		offset?: number;
		pull?: number;
		push?: number;
		span?: number;
	};
}
/** Block parameters for urn:block:view-engine:contentpagewithtabs */

export interface ContentpagewithtabsBlockParams {
	pageTitle?: {
		level?: 1 | 2 | 3 | 4 | 5;
		italic?: boolean;
		underline?: boolean;
		type?: 'secondary' | 'success' | 'warning' | 'danger';
		/**
		 * Localized String Values
		 */
		text?: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
		ellipsis?: boolean;
		/**
		 * Override the default font size for a font level. This can be used to style elements while maintaining accessibility.
		 */
		sizeOverride?: number;
	};
	/**
	 * By default, width will be 100%
	 */
	maxWidth?: string;
	/**
	 * Controls whether or not the position of the tabs is sticky.
	 */
	sticky?: boolean;
	/**
	 * Background color of the tabs bar.
	 */
	backgroundColor?: string;
	/**
	 * Offset of the title in a 24 grid layout, used by the view-engine-pilet as an antd Col prop
	 */
	titleOffset?: number;
	/**
	 * Width of the tab content area, defaults to 100%
	 */
	contentWidth?: string;
	/**
	 * Width of the layout content area of the block, defaults to 100%
	 */
	layoutWidth?: string;
	/**
	 * Default is screen.MD
	 */
	containerPaddingLargeScreen?: string;
	/**
	 * Default is screen.MD
	 */
	containerPaddingSmallScreen?: string;
}
/** Zone parameters for urn:zone:view-engine:contentpagewithtabs:page:title */

export interface ContentpagewithtabsPageTitleZoneParams {
	/**
	 * Tour step registration
	 */
	tourStep?: {
		/**
		 * Title of the tour step dialog
		 */
		title: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
		/**
		 * Description of the tour step dialog
		 */
		description?: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
		placement?:
			| 'left'
			| 'leftTop'
			| 'leftBottom'
			| 'right'
			| 'rightTop'
			| 'rightBottom'
			| 'top'
			| 'topLeft'
			| 'topRight'
			| 'bottom'
			| 'bottomLeft'
			| 'bottomRight'
			| 'center';
		/**
		 * Style of tour step dialog
		 */
		style?: {
			width?: string | number;
		};
		weight: number;
		analyticsId?: string;
	};
}
/** Zone parameters for urn:zone:view-engine:contentpagewithtabs:title:inline-meta */

export interface ContentpagewithtabsTitleInlineMetaZoneParams {}
/** Zone parameters for urn:zone:view-engine:contentpagewithtabs:header:inline-actions */

export interface ContentpagewithtabsHeaderInlineActionsZoneParams {}
/** Zone parameters for urn:zone:view-engine:contentpagewithtabs:metadata */

export interface ContentpagewithtabsMetadataZoneParams {}
/** Zone parameters for urn:zone:view-engine:contentpagewithtabs:tab */

export interface ContentpagewithtabsTabZoneParams {
	/**
	 * Localized String Values
	 */
	title: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	/**
	 * Tour step registration
	 */
	tourStep?: {
		/**
		 * Title of the tour step dialog
		 */
		title: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
		/**
		 * Description of the tour step dialog
		 */
		description?: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
		placement?:
			| 'left'
			| 'leftTop'
			| 'leftBottom'
			| 'right'
			| 'rightTop'
			| 'rightBottom'
			| 'top'
			| 'topLeft'
			| 'topRight'
			| 'bottom'
			| 'bottomLeft'
			| 'bottomRight'
			| 'center';
		/**
		 * Style of tour step dialog
		 */
		style?: {
			width?: string | number;
		};
		weight: number;
		analyticsId?: string;
	};
	/**
	 * Number of items within a tab
	 */
	tabContentCount?: number;
	/**
	 * Analytics id for pendo tracking
	 */
	analyticsId?: string;
}
/** Block parameters for urn:block:view-engine:contentpage */

export interface ContentpageBlockParams {
	pageTitle?: {
		level?: 1 | 2 | 3 | 4 | 5;
		italic?: boolean;
		underline?: boolean;
		type?: 'secondary' | 'success' | 'warning' | 'danger';
		/**
		 * Localized String Values
		 */
		text?: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
		ellipsis?: boolean;
		/**
		 * Override the default font size for a font level. This can be used to style elements while maintaining accessibility.
		 */
		sizeOverride?: number;
	};
	/**
	 * By default, width will be 100%
	 */
	maxWidth?: string;
	titleOffset?: number;
	showBetaTag?: boolean;
	/**
	 * By default, width will be 100%
	 */
	contentWidth?: string;
	/**
	 * By default, width will be 100%
	 */
	layoutWidth?: string;
	/**
	 * Controls whether or not the content area is scrollable. Default value is false.
	 */
	scrollContent?: boolean;
}
/** Zone parameters for urn:zone:view-engine:contentpage:title:inline-meta */

export interface ContentpageTitleInlineMetaZoneParams {}
/** Zone parameters for urn:zone:view-engine:contentpage:header:inline-actions */

export interface ContentpageHeaderInlineActionsZoneParams {}
/** Zone parameters for urn:zone:view-engine:contentpage:metadata */

export interface ContentpageMetadataZoneParams {}
/** Zone parameters for urn:zone:view-engine:contentpage:content */

export interface ContentpageContentZoneParams {}
/** Block parameters for urn:block:view-engine:dropdown-button */

/**
 * Allows navigation to a path from a dropdown option
 */
export type NavigateToPathFromDropdown = DropdownOptionBase & {
	path: string;
};
/**
 * The different icons that can be used in a dropdown
 */
export type IconTypes = 'blanktab' | 'usetemplate';
/**
 * Open a modal from a dropdown option
 */
export type OpenModalFromDropdown = DropdownOverlayBase & {
	/**
	 * additional params passed to the registered overlay component
	 */
	params?: {
		[k: string]: unknown;
	};
	/**
	 * The registered modal name
	 */
	modalName: string;
	modalProps?: {
		title?: DropdownLocalizedString;
	};
};
/**
 * The base for any overlay option in a dropdown
 */
export type DropdownOverlayBase = DropdownOptionBase & {
	onCompleteInteraction?: OnCompleteInteraction;
};
/**
 * Open a drawer from a dropdown option
 */
export type OpenDrawerFromDropdown = DropdownOverlayBase & {
	/**
	 * additional params passed to the registered overlay component
	 */
	params?: {
		[k: string]: unknown;
	};
	/**
	 * The registered drawer name
	 */
	drawerName: string;
	drawerProps?: {
		title?: DropdownLocalizedString;
	};
};
/**
 * Creates a sub menu of more dropdown items
 */
export type OpenMoreOptionsFromDropdown = DropdownOptionBase & {
	children: DropdownButtonOptions;
};
export type DropdownButtonOptions = (
	| NavigateToPathFromDropdown
	| OpenModalFromDropdown
	| OpenDrawerFromDropdown
	| OpenMoreOptionsFromDropdown
)[];

export interface DropdownButtonBlockParams {
	buttonText?: DropdownLocalizedString;
	size?: 'large' | 'middle' | 'small';
	shape?: 'default' | 'circle' | 'round';
	disabled?: boolean;
	type?: 'primary' | 'dashed' | 'link' | 'text' | 'default';
	options?: DropdownButtonOptions;
	tourStep?: TourParams;
}
/**
 * Localized String Values
 */
export interface DropdownLocalizedString {
	en?: string;
	es?: string;
	de?: string;
	fr?: string;
	it?: string;
	ja?: string;
	nl?: string;
	'pt-BR'?: string;
}
/**
 * The base for any option in a dropdown
 */
export interface DropdownOptionBase {
	label: DropdownLocalizedString;
	icon?: IconTypes;
	/**
	 * Will show the option in a disabled state
	 */
	disabled?: boolean;
	/**
	 * Will filter out the option of this value is set to true
	 */
	hidden?: boolean;
	danger?: boolean;
	tag?: DropdownTagParams;
	capabilityRequirements?: CapabilityRequirements;
}
export interface DropdownTagParams {
	tagText?: DropdownLocalizedString;
	tooltipText?: DropdownLocalizedString;
}
/**
 * Describe AST, preference and entitlement requirements for showing the dropdown
 */
export interface CapabilityRequirements {
	booleanPreferenceASTs?: string[];
	entitlementdefRIDs?: string[];
}
export interface OnCompleteInteraction {
	eventName: string;
	/**
	 * Describe the payload that should be emitted with the event. Can use the payload from the drawer interaction as a template source with {{data}}.
	 */
	eventPayload?: {
		[k: string]: unknown;
	};
}
export interface TourParams {
	title?: DropdownLocalizedString;
	description?: DropdownLocalizedString;
	weight: number;
	style?: {
		width?: string;
	};
	placement?:
		| 'left'
		| 'leftTop'
		| 'leftBottom'
		| 'right'
		| 'rightTop'
		| 'rightBottom'
		| 'top'
		| 'topLeft'
		| 'topRight'
		| 'bottom'
		| 'bottomLeft'
		| 'bottomRight'
		| 'center';
	analyticsId?: string;
}
/** Block parameters for urn:block:view-engine:context-menu */

export type ContextMenuOptions = (
	| {
			/**
			 * Localized String Values
			 */
			label: {
				en?: string;
				es?: string;
				de?: string;
				fr?: string;
				it?: string;
				ja?: string;
				nl?: string;
				'pt-BR'?: string;
			};
			path: string;
			capabilityRequirements?: CapabilityRequirements;
	  }
	| {
			/**
			 * additional params passed to the registered overlay component
			 */
			params?: {
				[k: string]: unknown;
			};
			/**
			 * Localized String Values
			 */
			label: {
				en?: string;
				es?: string;
				de?: string;
				fr?: string;
				it?: string;
				ja?: string;
				nl?: string;
				'pt-BR'?: string;
			};
			modalName: string;
			modalProps?: {
				/**
				 * Localized String Values
				 */
				title?: {
					en?: string;
					es?: string;
					de?: string;
					fr?: string;
					it?: string;
					ja?: string;
					nl?: string;
					'pt-BR'?: string;
				};
			};
			capabilityRequirements?: CapabilityRequirements;
			onCompleteInteraction?: OnCompleteInteraction;
	  }
	| {
			/**
			 * additional params passed to the registered overlay component
			 */
			params?: {
				[k: string]: unknown;
			};
			/**
			 * Localized String Values
			 */
			label: {
				en?: string;
				es?: string;
				de?: string;
				fr?: string;
				it?: string;
				ja?: string;
				nl?: string;
				'pt-BR'?: string;
			};
			drawerName: string;
			drawerProps?: {
				/**
				 * Localized String Values
				 */
				title?: {
					en?: string;
					es?: string;
					de?: string;
					fr?: string;
					it?: string;
					ja?: string;
					nl?: string;
					'pt-BR'?: string;
				};
			};
			capabilityRequirements?: CapabilityRequirements;
	  }
	| {
			/**
			 * Localized String Values
			 */
			label: {
				en?: string;
				es?: string;
				de?: string;
				fr?: string;
				it?: string;
				ja?: string;
				nl?: string;
				'pt-BR'?: string;
			};
			children: ContextMenuOptions;
			capabilityRequirements?: CapabilityRequirements;
			onCompleteInteraction?: OnCompleteInteraction;
	  }
)[];

export interface ContextMenuBlockParams {
	options: ContextMenuOptions;
	/**
	 * Localized String Values
	 */
	tooltipTitle?: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
}
export interface CapabilityRequirements {
	booleanPreferenceASTs?: string[];
	entitlementdefRIDs?: string[];
}
export interface OnCompleteInteraction {
	eventName: string;
	/**
	 * Describe the payload that should be emitted with the event. Can use the payload from the drawer interaction as a template source with {{data}}.
	 */
	eventPayload?: {
		[k: string]: unknown;
	};
}
/** Block parameters for urn:block:view-engine:tabs */

export type DropdownIconTypes = 'blanktab' | 'usetemplate';
/**
 * Supported options to create a new tab in the block
 */
export type AddTabOptions = (
	| AddTabModalOption
	| AddTabDrawerOption
	| AddTabOptionGroup
)[];

export interface TabsBlockParams {
	/**
	 * @deprecated
	 * Supported tab blocks
	 */
	supportedTabBlocks?: SupportedTabBlock[];
	/**
	 * Allow Tabs block to be editable. Default value is false.
	 */
	editable?: boolean;
	addTabOptions?: AddTabOptions;
	/**
	 * Unloads tab contents when navigated away from. Default value is false
	 */
	destroyInactiveTabPane?: boolean;
	/**
	 * Customize the query param in the URL to control the component instance's query key. Default query parameter key to control active tab is activeTab.
	 */
	activeTabQueryParamKey?: string;
	/**
	 * Enable scrolling of the tab content. Default value is false.
	 */
	scrollTab?: boolean;
	/**
	 * Enable adding of new tabs. Default value is false.
	 */
	canAddTab?: boolean;

	addTabLabel?: TabLocalizedString;
}
/**
 * Tab block
 */
export interface SupportedTabBlock {
	label?: string;
	pluginId?: string;
	blockRID?: string;
	blockParameters?: {
		[k: string]: unknown;
	};
}
export interface AddTabModalOption {
	/**
	 * additional params passed to the registered overlay component
	 */
	params?: {
		[k: string]: unknown;
	};
	/**
	 * Localized String Values
	 */
	label: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	modalName: string;
	modalProps?: {
		/**
		 * Localized String Values
		 */
		title?: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
	};
	createTabZoneParameters?: CreateTabZoneParameters;
	onCompleteInteraction?: OnCompleteInteraction;
	icon?: DropdownIconTypes;
	disabled?: boolean;
	danger?: boolean;
}
export interface CreateTabZoneParameters {
	/**
	 * Localized String Values
	 */
	label: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	closable?: boolean;
	blocks?: {
		pluginId: string;
		blockName: string;
		blockParameters?: {
			[k: string]: unknown;
		};
	}[];
	/**
	 * The expected tab key of a new tab
	 */
	key?: string;
}
export interface OnCompleteInteraction {
	eventName: string;
	/**
	 * Describe the payload that should be emitted with the event. Can use the payload from the drawer interaction as a template source with {{data}}.
	 */
	eventPayload?: {
		[k: string]: unknown;
	};
}
export interface AddTabDrawerOption {
	/**
	 * additional params passed to the registered overlay component
	 */
	params?: {
		[k: string]: unknown;
	};
	/**
	 * Localized String Values
	 */
	label: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	drawerName: string;
	drawerProps?: {
		/**
		 * Localized String Values
		 */
		title?: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
	};
	createTabZoneParameters?: CreateTabZoneParameters;
	onCompleteInteraction?: OnCompleteInteraction;
}
export interface AddTabOptionGroup {
	/**
	 * Localized String Values
	 */
	label: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	children: AddTabOptions;
}
/** Zone parameters for urn:zone:view-engine:tabs:tab */

/**
 * Navigate to a path from a tab option
 */
export type TabNavigationOption = TabOptionsBase & {
	path: string;
};
export type TabIconTypes = 'blanktab' | 'usetemplate';
export type TabModalOption = TabOverlayOptionsBase & {
	/**
	 * additional params passed to the registered overlay component
	 */
	params?: {
		[k: string]: unknown;
	};
	modalName: string;
	modalProps?: {
		title?: TabLocalizedString;
	};
};
/**
 * The base for any overlay option in the tab options
 */
export type TabOverlayOptionsBase = TabOptionsBase & {
	onCompleteInteraction?: OnCompleteInteraction;
};
export type TabDrawerOption = TabOverlayOptionsBase & {
	/**
	 * additional params passed to the registered overlay component
	 */
	params?: {
		[k: string]: unknown;
	};
	drawerName: string;
	drawerProps?: {
		title?: TabLocalizedString;
	};
};
export type TabOptionGroup = TabOptionsBase & {
	children: TabOptions;
};
export type TabEventOption = TabOptionsBase & {
	eventName: string;
	/**
	 * Describe the payload that should be emitted with the event.
	 */
	eventPayload?: {
		[k: string]: unknown;
	};
};
/**
 * Options that are triggered on click of a tab that is already active
 */
export type TabOptions = (
	| TabNavigationOption
	| TabModalOption
	| TabDrawerOption
	| TabOptionGroup
	| TabEventOption
)[];

export interface TabsTabZoneParams {
	label: TabLocalizedString;
	closable?: boolean;
	tabOptions?: TabOptions;
	/**
	 * Unloads tab contents when navigated away from. Default value is false
	 */
	destroyInactiveTabPane?: boolean;
	/**
	 * The tab key that will be used in URL Query parameters. Default value will be the zone id or RID.
	 */
	key?: string;
}
/**
 * Localized String Values
 */
export interface TabLocalizedString {
	en?: string;
	es?: string;
	de?: string;
	fr?: string;
	it?: string;
	ja?: string;
	nl?: string;
	'pt-BR'?: string;
}
/**
 * The base for any tab more options
 */
export interface TabOptionsBase {
	label: TabLocalizedString;
	icon?: TabIconTypes;
	capabilityRequirements?: CapabilityRequirements;
	tag?: TabTagParams;
	/**
	 * Will display the option in a disabled state
	 */
	disabled?: boolean;
	/**
	 * Will filter out the option of this value is set to true
	 */
	hidden?: boolean;
	danger?: boolean;
}
export interface CapabilityRequirements {
	booleanPreferenceASTs?: string[];
	entitlementdefRIDs?: string[];
}
export interface TabTagParams {
	tagText?: TabLocalizedString;
	tooltipText?: TabLocalizedString;
}
export interface OnCompleteInteraction {
	eventName: string;
	/**
	 * Describe the payload that should be emitted with the event. Can use the payload from the drawer interaction as a template source with {{data}}.
	 */
	eventPayload?: {
		[k: string]: unknown;
	};
}
/** Block parameters for urn:block:view-engine:view-loader */

/**
 * Should include one of containerRID, viewRID or viewId (in specificity order) to query a view and render it.
 */
export interface ViewLoaderBlockParams {
	/**
	 * Load views by containerRID
	 */
	containerRID?: string;
	/**
	 * Load a view by view RID value
	 */
	viewRID?: string;
	/**
	 * Load a shared view by id value
	 */
	viewId?: string;
	/**
	 * Dictionary of data to provide to the loaded view.
	 */
	contractData?: {
		[k: string]: string | number | boolean;
	};
	/**
	 * Dictionary of metadata to provide when querying for views. Currently limited to strings.
	 */
	metadata?: {
		[k: string]: string;
	};
}
/** Block parameters for urn:block:view-engine:sharefile-cube */

export interface SharefileCubeBlockParams {
	color?: string;
}
/** Block parameters for urn:block:view-engine:dashboard-layout */

export interface DashboardLayoutBlockParams {}
/** Zone parameters for urn:zone:view-engine:dashboard-layout:quick */

export interface DashboardLayoutQuickZoneParams {}
/** Zone parameters for urn:zone:view-engine:dashboard-layout:primary */

export interface DashboardLayoutPrimaryZoneParams {}
/** Zone parameters for urn:zone:view-engine:dashboard-layout:secondary */

export interface DashboardLayoutSecondaryZoneParams {}
/** Block parameters for urn:block:view-engine:view:dashboard */

export interface ViewDashboardBlockParams {}
/** Zone parameters for urn:zone:view-engine:view:dashboard:tile */

export interface ViewDashboardTileZoneParams {
	/**
	 * Localized String Values
	 */
	title: {
		en?: string;
		es?: string;
		de?: string;
		fr?: string;
		it?: string;
		ja?: string;
		nl?: string;
		'pt-BR'?: string;
	};
	viewAll: {
		/**
		 * Localized String Values
		 */
		label: {
			en?: string;
			es?: string;
			de?: string;
			fr?: string;
			it?: string;
			ja?: string;
			nl?: string;
			'pt-BR'?: string;
		};
	};
}

export namespace BlockNames {
	export const EventButton = 'urn:block:view-engine:event-button' as const;
	export type EventButton = 'urn:block:view-engine:event-button';
	export const Card = 'urn:block:view-engine:card' as const;
	export type Card = 'urn:block:view-engine:card';
	export const Text = 'urn:block:view-engine:text' as const;
	export type Text = 'urn:block:view-engine:text';
	export const Tag = 'urn:block:view-engine:tag' as const;
	export type Tag = 'urn:block:view-engine:tag';
	export const Title = 'urn:block:view-engine:title' as const;
	export type Title = 'urn:block:view-engine:title';
	export const Space = 'urn:block:view-engine:space' as const;
	export type Space = 'urn:block:view-engine:space';
	export const Row = 'urn:block:view-engine:row' as const;
	export type Row = 'urn:block:view-engine:row';
	export const Contentpagewithtabs = 'urn:block:view-engine:contentpagewithtabs' as const;
	export type Contentpagewithtabs = 'urn:block:view-engine:contentpagewithtabs';
	export const Contentpage = 'urn:block:view-engine:contentpage' as const;
	export type Contentpage = 'urn:block:view-engine:contentpage';
	export const DropdownButton = 'urn:block:view-engine:dropdown-button' as const;
	export type DropdownButton = 'urn:block:view-engine:dropdown-button';
	export const ContextMenu = 'urn:block:view-engine:context-menu' as const;
	export type ContextMenu = 'urn:block:view-engine:context-menu';
	export const Tabs = 'urn:block:view-engine:tabs' as const;
	export type Tabs = 'urn:block:view-engine:tabs';
	export const ViewLoader = 'urn:block:view-engine:view-loader' as const;
	export type ViewLoader = 'urn:block:view-engine:view-loader';
	export const SharefileCube = 'urn:block:view-engine:sharefile-cube' as const;
	export type SharefileCube = 'urn:block:view-engine:sharefile-cube';
	export const DashboardLayout = 'urn:block:view-engine:dashboard-layout' as const;
	export type DashboardLayout = 'urn:block:view-engine:dashboard-layout';
	export const ViewDashboard = 'urn:block:view-engine:view:dashboard' as const;
	export type ViewDashboard = 'urn:block:view-engine:view:dashboard';
}

export namespace ZoneTypes {
	export const CardTab = 'urn:zone:view-engine:card:tab' as const;
	export type CardTab = 'urn:zone:view-engine:card:tab';
	export const CardContent = 'urn:zone:view-engine:card:content' as const;
	export type CardContent = 'urn:zone:view-engine:card:content';
	export const SpaceContent = 'urn:zone:view-engine:space:content' as const;
	export type SpaceContent = 'urn:zone:view-engine:space:content';
	export const RowCol = 'urn:zone:view-engine:row:col' as const;
	export type RowCol = 'urn:zone:view-engine:row:col';
	export const ContentpagewithtabsPageTitle =
		'urn:zone:view-engine:contentpagewithtabs:page:title' as const;
	export type ContentpagewithtabsPageTitle =
		'urn:zone:view-engine:contentpagewithtabs:page:title';
	export const ContentpagewithtabsTitleInlineMeta =
		'urn:zone:view-engine:contentpagewithtabs:title:inline-meta' as const;
	export type ContentpagewithtabsTitleInlineMeta =
		'urn:zone:view-engine:contentpagewithtabs:title:inline-meta';
	export const ContentpagewithtabsHeaderInlineActions =
		'urn:zone:view-engine:contentpagewithtabs:header:inline-actions' as const;
	export type ContentpagewithtabsHeaderInlineActions =
		'urn:zone:view-engine:contentpagewithtabs:header:inline-actions';
	export const ContentpagewithtabsMetadata =
		'urn:zone:view-engine:contentpagewithtabs:metadata' as const;
	export type ContentpagewithtabsMetadata =
		'urn:zone:view-engine:contentpagewithtabs:metadata';
	export const ContentpagewithtabsTab =
		'urn:zone:view-engine:contentpagewithtabs:tab' as const;
	export type ContentpagewithtabsTab = 'urn:zone:view-engine:contentpagewithtabs:tab';
	export const ContentpageTitleInlineMeta =
		'urn:zone:view-engine:contentpage:title:inline-meta' as const;
	export type ContentpageTitleInlineMeta =
		'urn:zone:view-engine:contentpage:title:inline-meta';
	export const ContentpageHeaderInlineActions =
		'urn:zone:view-engine:contentpage:header:inline-actions' as const;
	export type ContentpageHeaderInlineActions =
		'urn:zone:view-engine:contentpage:header:inline-actions';
	export const ContentpageMetadata = 'urn:zone:view-engine:contentpage:metadata' as const;
	export type ContentpageMetadata = 'urn:zone:view-engine:contentpage:metadata';
	export const ContentpageContent = 'urn:zone:view-engine:contentpage:content' as const;
	export type ContentpageContent = 'urn:zone:view-engine:contentpage:content';
	export const TabsTab = 'urn:zone:view-engine:tabs:tab' as const;
	export type TabsTab = 'urn:zone:view-engine:tabs:tab';
	export const DashboardLayoutQuick =
		'urn:zone:view-engine:dashboard-layout:quick' as const;
	export type DashboardLayoutQuick = 'urn:zone:view-engine:dashboard-layout:quick';
	export const DashboardLayoutPrimary =
		'urn:zone:view-engine:dashboard-layout:primary' as const;
	export type DashboardLayoutPrimary = 'urn:zone:view-engine:dashboard-layout:primary';
	export const DashboardLayoutSecondary =
		'urn:zone:view-engine:dashboard-layout:secondary' as const;
	export type DashboardLayoutSecondary =
		'urn:zone:view-engine:dashboard-layout:secondary';
	export const ViewDashboardTile = 'urn:zone:view-engine:view:dashboard:tile' as const;
	export type ViewDashboardTile = 'urn:zone:view-engine:view:dashboard:tile';
}

export type EventButtonProps = ShellProps & BlockParams & EventButtonBlockParams;
export type CardProps = ShellProps & BlockParams & CardBlockParams;
export type TextProps = ShellProps & BlockParams & TextBlockParams;
export type TagProps = ShellProps & BlockParams & TagBlockParams;
export type TitleProps = ShellProps & BlockParams & TitleBlockParams;
export type SpaceProps = ShellProps & BlockParams & SpaceBlockParams;
export type RowProps = ShellProps & BlockParams & RowBlockParams;
export type ContentpagewithtabsProps = ShellProps &
	BlockParams &
	ContentpagewithtabsBlockParams;
export type ContentpageProps = ShellProps & BlockParams & ContentpageBlockParams;
export type DropdownButtonProps = ShellProps & BlockParams & DropdownButtonBlockParams;
export type ContextMenuProps = ShellProps & BlockParams & ContextMenuBlockParams;
export type TabsProps = ShellProps & BlockParams & TabsBlockParams;
export type ViewLoaderProps = ShellProps & BlockParams & ViewLoaderBlockParams;
export type SharefileCubeProps = ShellProps & BlockParams & SharefileCubeBlockParams;
export type DashboardLayoutProps = ShellProps & BlockParams & DashboardLayoutBlockParams;
export type ViewDashboardProps = ShellProps & BlockParams & ViewDashboardBlockParams;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		['urn:block:view-engine:event-button']: BlockParams & EventButtonBlockParams;
		['urn:block:view-engine:card']: BlockParams & CardBlockParams;
		['urn:block:view-engine:text']: BlockParams & TextBlockParams;
		['urn:block:view-engine:tag']: BlockParams & TagBlockParams;
		['urn:block:view-engine:title']: BlockParams & TitleBlockParams;
		['urn:block:view-engine:space']: BlockParams & SpaceBlockParams;
		['urn:block:view-engine:row']: BlockParams & RowBlockParams;
		['urn:block:view-engine:contentpagewithtabs']: BlockParams &
			ContentpagewithtabsBlockParams;
		['urn:block:view-engine:contentpage']: BlockParams & ContentpageBlockParams;
		['urn:block:view-engine:dropdown-button']: BlockParams & DropdownButtonBlockParams;
		['urn:block:view-engine:context-menu']: BlockParams & ContextMenuBlockParams;
		['urn:block:view-engine:tabs']: BlockParams & TabsBlockParams;
		['urn:block:view-engine:view-loader']: BlockParams & ViewLoaderBlockParams;
		['urn:block:view-engine:sharefile-cube']: BlockParams & SharefileCubeBlockParams;
		['urn:block:view-engine:dashboard-layout']: BlockParams & DashboardLayoutBlockParams;
		['urn:block:view-engine:view:dashboard']: BlockParams & ViewDashboardBlockParams;
	}
}

declare module '@sharefiledev/solution-view-engine' {
	interface BlockCustomExtensionSlotMap extends PiralCustomExtensionSlotMap {}

	interface PiletExtensionApi extends PiletApi {}

	interface ViewEngineCustomZoneParametersMap {
		['urn:zone:view-engine:card:tab']: CardTabZoneParams;
		['urn:zone:view-engine:card:content']: CardContentZoneParams;
		['urn:zone:view-engine:space:content']: SpaceContentZoneParams;
		['urn:zone:view-engine:row:col']: RowColZoneParams;
		['urn:zone:view-engine:contentpagewithtabs:page:title']: ContentpagewithtabsPageTitleZoneParams;
		['urn:zone:view-engine:contentpagewithtabs:title:inline-meta']: ContentpagewithtabsTitleInlineMetaZoneParams;
		['urn:zone:view-engine:contentpagewithtabs:header:inline-actions']: ContentpagewithtabsHeaderInlineActionsZoneParams;
		['urn:zone:view-engine:contentpagewithtabs:metadata']: ContentpagewithtabsMetadataZoneParams;
		['urn:zone:view-engine:contentpagewithtabs:tab']: ContentpagewithtabsTabZoneParams;
		['urn:zone:view-engine:contentpage:title:inline-meta']: ContentpageTitleInlineMetaZoneParams;
		['urn:zone:view-engine:contentpage:header:inline-actions']: ContentpageHeaderInlineActionsZoneParams;
		['urn:zone:view-engine:contentpage:metadata']: ContentpageMetadataZoneParams;
		['urn:zone:view-engine:contentpage:content']: ContentpageContentZoneParams;
		['urn:zone:view-engine:tabs:tab']: TabsTabZoneParams;
		['urn:zone:view-engine:dashboard-layout:quick']: DashboardLayoutQuickZoneParams;
		['urn:zone:view-engine:dashboard-layout:primary']: DashboardLayoutPrimaryZoneParams;
		['urn:zone:view-engine:dashboard-layout:secondary']: DashboardLayoutSecondaryZoneParams;
		['urn:zone:view-engine:view:dashboard:tile']: ViewDashboardTileZoneParams;
	}

	interface ViewEngineCustomZoneContractDataMap {
		['urn:zone:view-engine:card:tab']: never;
		['urn:zone:view-engine:card:content']: never;
		['urn:zone:view-engine:space:content']: never;
		['urn:zone:view-engine:row:col']: never;
		['urn:zone:view-engine:contentpagewithtabs:page:title']: never;
		['urn:zone:view-engine:contentpagewithtabs:title:inline-meta']: never;
		['urn:zone:view-engine:contentpagewithtabs:header:inline-actions']: never;
		['urn:zone:view-engine:contentpagewithtabs:metadata']: never;
		['urn:zone:view-engine:contentpagewithtabs:tab']: never;
		['urn:zone:view-engine:contentpage:title:inline-meta']: never;
		['urn:zone:view-engine:contentpage:header:inline-actions']: never;
		['urn:zone:view-engine:contentpage:metadata']: never;
		['urn:zone:view-engine:contentpage:content']: never;
		['urn:zone:view-engine:tabs:tab']: never;
		['urn:zone:view-engine:dashboard-layout:quick']: never;
		['urn:zone:view-engine:dashboard-layout:primary']: never;
		['urn:zone:view-engine:dashboard-layout:secondary']: never;
		['urn:zone:view-engine:view:dashboard:tile']: never;
	}
}
