import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';

const LazyRIDRoutingBlock = React.lazy(() => import('./RIDRoutingComponent'));

interface Props {
	piletApi: PiletApi;
}

export const AsyncRIDRoutingComponent = ({ piletApi }: Props) => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyRIDRoutingBlock piletApi={piletApi} />
		</React.Suspense>
	);
};
