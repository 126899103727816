import React from 'react';

export const LazyDevelopmentPage = React.lazy(() => import('./Development'));

export const LazyViewEngineDevelopmentPage = React.lazy(
	() => import('./ViewEngineDevelopment')
);

export const LazyRIDPage = React.lazy(() => import('./RIDLandingPage'));

export const LazyViewRenderer = React.lazy(() => import('./ViewRenderer'));

export const LazyViewBuilder = React.lazy(() => import('./ViewBuilder'));
