import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import type { UiManifestV1 } from '@sharefiledev/solution-view-engine';
import { Spin } from 'antd';

const LazyBlockDevelopment = React.lazy(() => import('./BlockBuilder'));

interface Props {
	piletApi: PiletApi;
	initialManifest?: UiManifestV1;
	pluginKey?: string;
}

export const AsyncBlockDevelopment = ({
	piletApi,
	initialManifest,
	pluginKey,
}: Props) => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyBlockDevelopment
				piletApi={piletApi}
				pluginKey={pluginKey || 'pluginkeynotdefined'}
				initialManifest={initialManifest}
			/>
		</React.Suspense>
	);
};
